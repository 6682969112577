<template>
  <div>
    <h4 class="title hidden-xs">
      {{ $t("user_list") }}
    </h4>
    <h4 class="title hidden-sm hidden-md hidden-lg">
      {{
        creating
          ? $t("register_a_new_membership")
          : $tc("user", selectedUser ? 1 : 2)
      }}
    </h4>
    <div class="row hidden-sm hidden-md hidden-lg">
      <div class="col-xs-12" v-if="!creating">
        <div class="form-group">
          <div class="input-group">
            <input
              class="form-control"
              v-model="query"
              ref="inpquery"
              :disabled="selectedUser ? true : false"
            />
            <div
              v-if="query || selectedUser"
              class="input-group-addon btn btn-primary"
              @click="resetQuery"
            >
              <i :class="selectedUser ? 'fa fa-undo' : 'fa fa-close'"></i>
            </div>
            <div
              v-else
              class="input-group-addon btn btn-primary"
              @click="create"
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
        <div class="list-group">
          <template v-if="filteredItems.length && !selectedUser">
            <div
              class="list-group-item"
              v-for="item in filteredItems"
              :key="item.id"
              @click.stop.prevent="selectedUser = item"
            >
              <div>
                <div class="text-bold">
                  {{ item.email }}
                </div>
                <span class="text-normal pull-right">
                  {{ $t(item.is_active ? "active" : "inactive") }}
                </span>
              </div>
              <div>
                <small>{{
                  $utils.trim(`${item.first_name} ${item.last_name}`) ||
                  item.email
                }}</small>
              </div>
            </div>
          </template>
          <template v-else-if="!selectedUser">
            <div class="list-group-item text-center">
              {{ $t("no_result_found") }}
              <div>
                <div class="btn btn-sm btn-primary" @click="create">
                  {{ $t("create_account") }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="hidden-xs">
      <SearchableTable
        class="searchable-table"
        v-bind:items="items"
        v-bind:commands="commands"
        v-bind:fields="fields"
        v-bind:searchEnabled="true"
        v-bind:showSelected="showSelected"
        v-bind:pagination="true"
        v-bind:maxResult="(items || []).length"
        v-on:select="select"
        v-on:unselect="unselect"
        v-on:command="onCommand"
        searchClass="entity-search"
      />
    </div>
  </div>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
import SearchableTable from "@/components/searchable-table.vue";
import UserService from "@/services/user.js";
export default {
  name: "UsersTable",
  mixins: [MixinAlert],
  components: {
    SearchableTable
  },
  props: {
    users: {
      type: Array,
      required: true,
      default: () => []
    },
    showSelected: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    selected_user: null,
    sort_def: {
      column: "",
      asc: false
    },
    query: "",
    contract_owner_can_create_new_member: true,
    inviting: {},
    creating: false
  }),
  computed: {
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id;
    },
    items() {
      return this.users;
    },
    fields() {
      return [
        {
          name: "email",
          title: "email"
        },
        {
          name: "fullname",
          title: this.$t("name"),
          parser: (i) => {
            return i.first_name + " " + i.last_name;
          }
        },
        {
          name: "is_active",
          title: "active",
          parser: (i) => {
            return this.$t(i.is_active ? "active" : "inactive");
          }
        }
      ];
    },
    commands() {
      let self = this;
      let lst = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => this.$can("manage", "MembroDeContratoCadastro")
        }
      ];
      if (this.can("contract_owner_can_disable_member")) {
        lst.push({
          name: "toggleUser",
          title: (i) => {
            return this.$t(i.is_active ? "active" : "inactive");
          },
          icon: (i) => {
            return i.is_active
              ? "fa fa-toggle-on text-success"
              : "fa fa-toggle-off";
          },
          enabled: true
        });
      }
      if (this.can("contract_owner_can_invite_member")) {
        lst.push({
          name: "inviteUser",
          title: this.$t("send_invitation"),
          icon: (i) => {
            return i.id in self.inviting
              ? "fa fa-refresh fa-spin text-primary disabled"
              : i.is_active
                ? "fa fa-send text-gray"
                : "fa fa-send text-primary";
          },
          enabled: (i) => {
            return !i.is_active;
          }
        });
      }
      return lst;
    },
    selectedUser: {
      set(value) {
        if (value) {
          this.selected_user = value;
          this.$emit("select", value.email);
          this.query = value.email;
        } else {
          this.selected_user = null;
          this.$emit("unselect");
        }
      },
      get() {
        return this.selected_user;
      }
    },
    filteredItems() {
      return this.query
        ? this.items.filter((item) => this.$utils.queryStrAtr(this.query, item))
        : this.items;
    }
  },
  watch: {
    "$attrs.formUser"(n, o) {
      if (o && o != n) {
        this.resetQuery();
      }
    }
  },
  methods: {
    can(what) {
      let cfg = this?.$root?.config?.user_management || {};
      return what in cfg ? cfg[what] : true;
    },
    toggleUser(item) {
      console.log("toggleUser");
    },
    inviteUser(item) {
      this.busy = true;
      this.$set(this.inviting, item.id, true);
      let srv = new UserService();
      let payload = {
        id: item.id,
        contract_id: this.contract_id,
        email: item.email,
        create_user: false,
        create_invite: true,
        etag: item.etag
      };
      const _cb = (ret) => {
        this.busy = false;
        this.$delete(this.inviting, item.id);
        if (this.validateSaveResponse(ret, this.$t("invitation_sent"))) {
          this.$emit("changed");
        }
        this.showAlert();
      };
      srv.save(payload).then(_cb, _cb);
    },
    create() {
      this.creating = true;
      this.$emit("create");
    },
    onCommand(cmd) {
      if (cmd && cmd?.name in this && typeof this[cmd.name] === "function") {
        this[cmd.name](cmd.target);
        // console.log(cmd.target);
      }
    },
    unselect: function() {
      this.$emit("unselect");
    },
    select(item) {
      this.$emit("select", item.email);
    },
    resetQuery() {
      this.query = "";
      this.selectedUser = null;
      this.creating = false;
      // this.$nextTick(() => {
      //   if (this.$refs.inpquery) {
      //     this.$refs.inpquery.focus();
      //   }
      // });
    }
  },
  mounted() {
    this.contract_owner_can_create_new_member = this.$root.config.user_management.contract_owner_can_create_new_member;
  }
};
</script>

<style scoped>
.has-search .form-control-feedback {
  right: initial;
  left: 0;
  color: #ccc;
  z-index: 3;
}

.has-search .form-control {
  padding-right: 12px;
  padding-left: 34px;
  background-color: transparent;
}

.searchable-table {
  overflow: auto;
}
</style>
